<template>
    <div class="login">
        <h1 class="login__heading">
            Let's sign you in 🚀
        </h1>

        <form
            novalidate
            class="login__form"
            @submit.prevent="submit"
        >
            <div class="login__form-group">
                <label>
                    Email
                </label>

                <div class="login__input">
                    <input
                        v-model="form.email"
                        type="text"
                        placeholder="Email"
                    >
                </div>
            </div>

            <div class="login__form-group">
                <label>
                    Password
                </label>

                <div class="login__input">
                    <input
                        v-model="form.password"
                        type="password"
                        placeholder="Password"
                    >
                </div>
            </div>

            <button
                class="login__form-submit"
            >
                Login
            </button>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Login',
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
        }
    },
    methods: {
        ...mapActions(['loginWithFirebase']),
        submit() {
            return this.loginWithFirebase({
                email: this.form.email,
                password: this.form.password,
            })
        },
    },
}
</script>

<style lang="scss">
.login {
    &__heading {
        position: absolute;
        top: -60px;
        right: 0;
        left: 0;
        color: white;
        font-size: 20px;
        font-weight: 900;
    }

    &__form-group {
        display: flex;
        position: relative;
        flex-direction: column;

        label {
            color: #828282;
            font-size: 10px;
            text-align: left;
        }
    }

    &__input {
        position: relative;
        margin-bottom: 12px;
        padding-bottom: 6px;
        border-bottom: 1px solid #3a3a3a;

        input[type=text] {
            width: 100%;
            height: 24px;
            margin: auto;
            border: none;
            border-radius: 4px;
            outline: none;
            color: #3a3a3a;
            text-indent: 24px;

            &::placeholder {
                opacity: .6;
                color: #3a3a3a;
                font-size: 10px;
            }
        }

        input[type=password] {
            font: small-caption;
        }
    }

    &__form-submit {
        width: 100%;
        height: fit-content;
        padding: 12px 0;
        grid-area: cart;
        border: none;
        border-radius: 12px;
        color: white;
        background-color: #fd8700;
        font-size: 12px;
        font-weight: 600;
    }
}
</style>
